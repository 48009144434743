import {
  Component,
  Input,
} from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  FileLabel,
  LabelFieldOption,
  LabelFieldOptionType
} from 'app/types';

interface LabelPolicy {
  /** Whether the label is displayed or not. */
  displayable: boolean;
  /** The icon associated to the label. */
  icon: string;
  /** Whether the icon is a PrimeNG icon or not. */
  isPrimeNgIcon: boolean;
  /** The value of the label. */
  value: string;
  /** The css class of the label. */
  class: string;
  /** The function to call when the label is clicked. */
  onClick: (event: Event, op: OverlayPanel) => void
}

@Component({
  selector: 'side-panel-labels',
  templateUrl: './side-panel-labels.component.html',
  styleUrl: './side-panel-labels.component.scss'
})

export class SidePanelLabelsComponent {
  @Input() labels?: FileLabel[] = [];
  @Input() loading: boolean = false;

  options: LabelFieldOption[] = [];

  readonly LabelsMap: Map<LabelFieldOptionType, LabelPolicy> = new Map<LabelFieldOptionType, LabelPolicy> ([
    [LabelFieldOptionType.STATUS_VERIFIED, {
      displayable: true,
      icon: 'pi pi-verified',
      isPrimeNgIcon: true,
      value: 'Verified',
      class: 'verified-tag',
      onClick: () => null,
    }],
    [LabelFieldOptionType.STATUS_HAS_BEEN_UNLOCKED, {
      displayable: true,
      icon: 'assets/icons/shield-off.svg',
      isPrimeNgIcon: false,
      value: 'Verified (Edited)',
      class: 'lost-verified-tag',
      onClick: () => null,
    }],
    [LabelFieldOptionType.STATUS_VERIFICATION_EXPIRES_SOON, {
      displayable: true,
      icon: 'pi pi-clock',
      isPrimeNgIcon: true,
      value: 'Verified',
      class: 'expiring-verified-tag',
      onClick: () => null,
    }],
    [LabelFieldOptionType.VERIFICATION_PROCESS_PENDING, {
      displayable: false,
      icon: 'pi pi-clock',
      isPrimeNgIcon: true,
      value: 'Pending',
      class: 'pending-tag',
      onClick: () => null,
    }],
    [LabelFieldOptionType.VERIFICATION_PROCESS_REJECTED, {
      displayable: false,
      icon: 'pi pi-times',
      isPrimeNgIcon: true,
      value: 'Rejected',
      class: 'rejected-tag',
      onClick: () => null,
    }],
    [LabelFieldOptionType.STATUS_VERIFICATION_OUTDATED, {
      displayable: true,
      icon: 'pi pi-verified',
      isPrimeNgIcon: true,
      value: 'Verified (Outdated)',
      class: 'lost-verified-tag',
      onClick: () => null,
    }],
    [LabelFieldOptionType.NEEDS_UPDATE_FLAGGED, {
      displayable: true,
      icon: 'pi pi-flag',
      isPrimeNgIcon: true,
      value: 'Flagged',
      class: 'flagged-tag',
      onClick: (event: Event, op: OverlayPanel) => this.onTagClick(event, op)
    }],
  ]);

  onTagClick(event: Event, overlayPanel: OverlayPanel) {
    overlayPanel.toggle(event);
  }

  formatDate(date?: Date): string {
    if (!date) return '';

    const [year, month, day] = date.toString().split('T')[0].split('-');
    return `${day}/${month}/${year}`;
  }

  getFlaggedMessage() {
    const flaggedLabel = this.labels?.find((label) => label.selected_options.some((option) => option.type === LabelFieldOptionType.NEEDS_UPDATE_FLAGGED));

    return `Lastly flagged ${flaggedLabel?.labeled_by?.primary_email} (${this.formatDate(flaggedLabel?.labeled_by?.updated_at)}) because "${flaggedLabel?.reason}"`;
  }

  ngOnChanges() {
    this.options = [];
    this.labels?.forEach((label) => {
      this.options.push(...label.selected_options);
    });
  }
}
