@if (loading) {
  <div class="skeleton-activity">
    <p-skeleton height="70px" class="skeleton-activity" styleClass="mb-2" />
  </div>
} @else {
  <div class="activity-container">
    <span class="span-header">Activity</span>

    <!-- WORKFLOW HISTORY -->
    @if (workflows && workflows.length >= minimalSizeForHistory) {
      <p-panel collapsed [toggleable]="workflows.length > minimalSizeForHistory" collapseIcon="pi pi-chevron-down" expandIcon="pi pi-chevron-up">
        <ng-template pTemplate="header">
          <div class="activity-status-start">
            <i class="pi pi-verified"></i>
            <span>Verification - </span>
            @if (currentWorkFlow) {
              <p-tag
                class="worflow-status spaced-tag"
                [icon]="workflowStatesMap[currentWorkFlow.state].icon"
                [value]="workflowStatesMap[currentWorkFlow.state].value"
                [class]="workflowStatesMap[currentWorkFlow.state].class"
              />
            }
            @else {
              <span>No workflow started</span>
            }
            @if (currentWorkFlow?.has_exported_pdf) {
              <div class="pdf-exported" (mousedown)="exportPdfEvent.emit(currentWorkFlow?.id)">
                <i class="pi pi-download"></i>
                <span>PDF</span>
              </div>
            }
          </div>
          @if (currentWorkFlow?.state === Workflow.VALIDATED) {
            <div class="validated-date">
              {{ currentWorkFlow?.updated_at | date }}
            </div>
          }
        </ng-template>
        <p-timeline [value]="workflows.slice(1)" class="w-full md:w-20rem">
          <ng-template pTemplate="marker" let-event>
            <app-icon
              value="assets/icons/git-commit.svg"
            ></app-icon>
          </ng-template>
          <ng-template pTemplate="content" let-event>
            <div class="activity-status">
              {{ capitalizeFirstLetter(event.state) }}
              <p-tag
                class="worflow-status small-tag"
                [icon]="workflowStatesMap[stringToWorkflowState(event.state)].icon"
                [class]="workflowStatesMap[stringToWorkflowState(event.state)].class"
              />
              @if (event.has_exported_pdf) {
                <div class="pdf-exported" (mousedown)="exportPdfEvent.emit(event?.id)">
                  <i class="pi pi-download"></i>
                  <span>PDF</span>
                </div>
              }
            </div>
            <div class="date">{{ event.updated_at | date }}</div>
          </ng-template>
        </p-timeline>
    </p-panel>
    } @else {
      <div class="activity-status-start">
        <i class="pi pi-verified"></i>
        <span>Verification - No workflow started</span>
      </div>
    }

    <!-- CURRENT WORKFLOW ACTIONS BUTTONS -->
    @if (!locked && hasRights) {
      @if (currentWorkFlow?.state === Workflow.WAITING) {
        <div class="activity-actions">
          <p-button
            class="activity-button"
            label="Reject"
            [outlined]="true"
            size="small"
            (click)="rejectWorkflowEvent.emit()"
          />
          <p-button
            class="activity-button"
            label="Approve"
            size="small"
            (click)="approveWorkflowEvent.emit()"
          />
        </div>
      }
    }
    @if (
      currentWorkFlow?.state === Workflow.REJECTED ||
      currentWorkFlow?.state === null
    ) {
      <div class="activity-actions">
        <p-button
          class="activity-button"
          label="Start verification"
          size="small"
          (click)="startWorkflowEvent.emit()"
        />
      </div>
    } @if (currentWorkFlow?.state === Workflow.VALIDATED) {
      <div class="activity-actions">
        <p-button
          class="activity-button"
          label="Renew verification"
          size="small"
          (click)="startWorkflowEvent.emit()"
        />
      </div>
    }
  </div>
}
